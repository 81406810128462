import Document, { Html, Head, Main, NextScript, DocumentContext } from 'next/document'

export const GOOGLE_TAG_MANAGER_ID: string = process.env.GOOGLE_TAG_MANAGER_ID ?? ''
export const GOOGLE_ANALYTICS_TRACKING_ID: string = process.env.GOOGLE_ANALYTICS_TRACKING_ID ?? ''
export const GET_ATLAS_APP_ID: string = process.env.GET_ATLAS_APP_ID ?? ''
export const AMPLITUDE_API_KEY: string = process.env.AMPLITUDE_API_KEY ?? ''
export const HEAP_APP_ID: string = process.env.HEAP_APP_ID ?? ''
export const DIALOGFLOW_TOKEN: string = process.env.DIALOGFLOW_TOKEN ?? ''
export const PODIUM_TOKEN: string = process.env.PODIUM_TOKEN ?? ''
class MyDocument extends Document {
  static async getInitialProps(ctx: DocumentContext) {
    const initialProps = await Document.getInitialProps(ctx)
    return { ...initialProps }
  }

  render() {
    return (
      <Html>
        <Head>
          {GOOGLE_TAG_MANAGER_ID && (
            <script
              dangerouslySetInnerHTML={{
                __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${GOOGLE_TAG_MANAGER_ID}');`
              }}
            />
          )}
          <script
            defer
            src={`https://connect.podium.com/widget.js#ORG_TOKEN=${PODIUM_TOKEN}`}
            id="podium-widget"
            data-organization-api-token={`${PODIUM_TOKEN}`}
          ></script>
          <link rel="stylesheet" href="https://unpkg.com/leaflet/dist/leaflet.css" />
          <link rel="stylesheet" href="https://unpkg.com/react-leaflet-markercluster/dist/styles.min.css" />
          <script
            defer
            dangerouslySetInnerHTML={{
              __html: `window.heap = window.heap || [], heap.load = function(e, t) {
                window.heap.appid = e, window.heap.config = t = t || {};
                var r = document.createElement("script");
                r.type = "text/javascript", r.async = !0, r.src = "https://cdn.heapanalytics.com/js/heap-" + e + ".js";
                var a = document.getElementsByTagName("script")[0];
                a.parentNode.insertBefore(r, a);
                for (var n = function(e) {
                        return function() {
                            heap.push([e].concat(Array.prototype.slice.call(arguments, 0)))
                        }
                    }, p = ["addEventProperties", "addUserProperties", "clearEventProperties", "identify", "resetIdentity", "removeEventProperty", "setEventProperties", "track", "unsetEventProperty"], o = 0; o < p.length; o++) heap[p[o]] = n(p[o])
            };
            heap.load(${HEAP_APP_ID});`
            }}
          />
          {GOOGLE_ANALYTICS_TRACKING_ID && (
            <>
              <script async src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_TRACKING_ID}`} />
              <script
                dangerouslySetInnerHTML={{
                  __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GOOGLE_ANALYTICS_TRACKING_ID}', {
              page_path: window.location.pathname
            });
          `
                }}
              />
            </>
          )}
          <meta name="application-name" content="HomeRoom" />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="apple-mobile-web-app-status-bar-style" content="default" />
          <meta name="apple-mobile-web-app-title" content="HomeRoom" />
          <meta name="description" content="Roommate Living. Perfected." />
          <meta name="format-detection" content="telephone=no" />
          <meta name="mobile-web-app-capable" content="yes" />
          <meta name="msapplication-config" content="/icons/browserconfig.xml" />
          <meta name="msapplication-TileColor" content="#2B5797" />
          <meta name="msapplication-tap-highlight" content="no" />
          <meta name="theme-color" content="#000000" />
          <link rel="apple-touch-icon" href="/icons/touch-icon-iphone.png" />
          <link rel="apple-touch-icon" sizes="152x152" href="/icons/touch-icon-ipad.png" />
          <link rel="apple-touch-icon" sizes="180x180" href="/icons/touch-icon-iphone-retina.png" />
          <link rel="apple-touch-icon" sizes="167x167" href="/icons/touch-icon-ipad-retina.png" />
          <link rel="manifest" href="/manifest.json" />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:url" content="https://app.livehomeroom.com" />
          <meta name="twitter:title" content="HomeRoom" />
          <meta name="twitter:description" content="Roommate Living. Perfected." />
          <meta name="twitter:image" content="https://app.livehomeroom.com/icons/android-chrome-192x192.png" />
          {/*
          // TODO: May use later
          <meta name="twitter:creator" content="@DavidWShadow" />
          <link rel="mask-icon" href="/icons/safari-pinned-tab.svg" color="#5bbad5" />
          <meta property="og:image" content="https://app.livehomeroom.com/icons/apple-touch-icon.png" /> */}
          <meta property="og:type" content="website" />
          <meta property="og:title" content="HomeRoom" />
          <meta property="og:description" content="Roommate Living. Perfected." />
          <meta property="og:site_name" content="HomeRoom" />
          <meta property="og:url" content="https://app.livehomeroom.com" />
        </Head>

        <body>
          {GOOGLE_TAG_MANAGER_ID && (
            <noscript
              dangerouslySetInnerHTML={{
                __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${GOOGLE_TAG_MANAGER_ID}"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>`
              }}
            ></noscript>
          )}
          <Main />
          <NextScript />
        </body>
      </Html>
    )
  }
}

export default MyDocument
